const ProductPriceService = {
    getUnitNetPrice: product => {
        if (product.pricing.requiresRealTimePrice) {
            return { price: 0, priceDisplay: `${product.currencySymbol ? product.currencySymbol : ""}<span class='price-loading-spinner'></span>` };
        }

        if (product.isConfigured || product.isFixedConfiguration) {
            const price = getPrice(null, product.pricing.unitNetPrice, product.pricing.unitNetPriceDisplay, product.qtyOrdered);
            return { price: price.price, priceDisplay: price.priceDisplay };
        }

        const price = getPrice(product.pricing.unitRegularBreakPrices, product.pricing.unitNetPrice, product.pricing.unitNetPriceDisplay, product.qtyOrdered);
        return { price: price.price, priceDisplay: price.priceDisplay };
    },

    getUnitListPrice: product => {
        const price = getPrice(product.pricing.unitListBreakPrices, product.pricing.unitListPrice, product.pricing.unitListPriceDisplay, product.qtyOrdered);
        return { price: price.price, priceDisplay: price.priceDisplay };
    }
}

const getPrice = (breaks,price, priceToDisplay, qty) => {
    qty = !qty || qty === "0" ? 1 : qty;
    if (conditionBreakPrice(breaks, qty)) {
        return { price: price, priceDisplay: priceToDisplay };
    }

    const breakPrice = getBreakPrice(breaks, qty);
    if (breakPrice && (price < breakPrice.breakPrice)) {
        return { price: price, priceDisplay: priceToDisplay };
    }

    return { price: breakPrice.breakPrice, priceDisplay: breakPrice.breakPriceDisplay };
}

const conditionBreakPrice = (breaks, count) => {
    return !breaks || breaks.length === 0 || count === 0;
}

const getBreakPrice = (breaks, count) => {
    if (!breaks) {
        return null;
    }

    const copyBreaks = breaks.slice();
    copyBreaks.sort((a, b) => { return b.breakQty - a.breakQty; });
    for (let i = 0; i < copyBreaks.length; i++) {
        if (copyBreaks[i].breakQty <= count) {
            return copyBreaks[i];
        }
    }

    return copyBreaks[copyBreaks.length - 1];
}

export default ProductPriceService;