import React from 'react';
import ReactDOM from 'react-dom';
import Dashboard from './Dashboard';

window.initDashboardReact = (session, navigationLinks, settingsLinks, breadcrumbs, checkoutAddress, systemMessages) => {
    if (document.getElementById('appDashboard')) {
        if($('#appDashboard').children()) {
            ReactDOM.unmountComponentAtNode(document.getElementById('appDashboard'))
        }
        ReactDOM.render(
            <Dashboard session={session}
                navigationLinks={navigationLinks}
                settingsLinks={settingsLinks}
                breadcrumbs={breadcrumbs}
                checkoutAddress={checkoutAddress}
                systemMessages = {systemMessages}/>,
            document.getElementById('appDashboard')
        );
    }
}
