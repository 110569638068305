import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import DataTable from '@insitesoft/mobius/DataTable/DataTable';
import DataTableHead from '@insitesoft/mobius/DataTable/DataTableHead';
import DataTableBody from '@insitesoft/mobius/DataTable/DataTableBody';
import DataTableCell from '@insitesoft/mobius/DataTable/DataTableCell';
import Modal from '@insitesoft/mobius/Modal/Modal'
import Button from '@insitesoft/mobius/Button/Button';
import breakpointMediaQueries from '@insitesoft/mobius/utilities/breakpointMediaQueries';
import { theme, HSDataTableHeader, Arrow, HSDataTableRow, NoResults } from '../../theme';
import productPriceService from '../services/ProductPriceService';

const apiUrl = '/api/v1/wishlists';
const cartApiUrl = '/api/v1/carts/current/cartlines/wishlist/';

const HSWishlist = styled.section `
    width: 100%;
    margin-bottom: 15px;
`
const HSWishlistLink = styled.a `
    display: inline-block;
    line-height: 24px;
    margin-right: 5px;
    color: black !important;
    font-weight: 700 !important;
    text-transform: uppercase;
`

const HSCta = styled.a `
    text-transform: uppercase;
    color: #2d2d2d !important;
    font-size: 16px;
    font-weight: 700;
`
const HSModalFooter =  styled.div `
    text-align: right;
`

const Wishlist = () => {
    const [data, setData] = useState([]);
    const [deleteList, setDeleteList] = useState({});
    const [deleteUri, setDeleteUri] = useState('');
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('Date');
    const [itemsAddedCart, setItemsAddedCart] = useState(false);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(
                apiUrl, {
                    params: {
                        pageSize: 5
                    }
                }
            );

            const allData = await getAllData(result.data.wishListCollection);
            setData(allData);
            setDeleteList({});
        };

        const getAllData = async allList => {
            const pArray = allList.map(async item => {
                item = { ...item, ...await getAllWishListData(item.id)}
                //const listTotal = calculateListTotal(item);

                return {
                    ...item 
                    //TotalPrice: listTotal,
                    //TotalPriceToDisplay: "$" + listTotal.toLocaleString('en')
                }
            })
            const pAll = await Promise.all(pArray);
            return pAll
        }

        const getAllWishListData = id => {
            return axios.get(`${apiUrl}/${id}?expand=listlines`, {
                cancelToken: source.token
            })
            .then(response => response.data)
            .catch(error => console.log(error))
        }

        fetchData();
    }, [deleteUri]);

    const calculateListTotal = listModel => {
        if(!listModel.wishListLineCollection ) return 0;
    
        let listTotal = 0;
        listModel.wishListLineCollection.forEach(product => {
            if (product.pricing) {
                const unitNetPrice = productPriceService.getUnitNetPrice(product).price;
                const extendedNetPrice = Math.round(unitNetPrice *
                    product.qtyOrdered *
                    100) /
                    100;

                listTotal += extendedNetPrice;
            }
        });

        listTotal = Math.round(listTotal * 100) / 100;
        return listTotal;
    }

    const onDeleteList = list => {
        axios.delete(list.uri, {
            cancelToken: source.token
        })
        .then(async response => setDeleteUri(list.uri))
        .catch(error => console.log(error))
    }

    const onBuy = id => {
        axios.post(`${cartApiUrl}/${id}`, {
            cancelToken: source.token
        })
        .then(response => {
            setItemsAddedCart(true);
        })
        .catch(error => console.log(error))
    }

    const orderListBy = param => {
        let dir, sortList;
        if(param === orderBy) {
            dir = order === 'desc' ? 'asc' : 'desc';
        } else {
            dir = 'desc';
        }

        if  (dir === 'desc') {
            sortList = _orderBy(data, l => {
                if(param === 'updatedOn') {
                    return new Date(l.Date)
                }
                if(param === 'totalItemCount') {
                    return l.pagination ?  l.pagination.totalItemCount : 0
                }
                return l[param]
            }, 'desc');
        } else {
            sortList = _orderBy(data,  l => {
                if(param === 'updatedOn') {
                    return new Date(l.Date)
                }
                if(param === 'totalItemCount') {
                    return l.pagination ?  l.pagination.totalItemCount : 0
                }
                return l[param]
            }, 'asc');
        }

        setOrderBy(param);
        setOrder(dir)
        setData(sortList);

    };

    return (
        <HSWishlist>
        { data && data.length ?
        <>
            <DataTable css={theme.dataTable.defaultProps.css}>
                <DataTableHead css={theme.dataTableHead.defaultProps.css}>
                    <HSDataTableHeader  width='105'
                        css={theme.dataTableHeader.defaultProps.css}
                        orderBy={orderBy === 'updatedOn'}
                        onClick={() => orderListBy('updatedOn')}>
                        Date
                        <Arrow order={order}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                        </Arrow>
                    </HSDataTableHeader>

                    <HSDataTableHeader
                        css={theme.dataTableHeader.defaultProps.css}
                        orderBy={orderBy === 'name'}
                        onClick={() => orderListBy('name')}>
                        List Name
                        <Arrow order={order}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                        </Arrow>
                    </HSDataTableHeader>

                    <HSDataTableHeader width="80" alignX="center"
                        css={theme.dataTableHeader.defaultProps.css}
                        orderBy={orderBy === 'totalItemCount'}
                        onClick={() => orderListBy('totalItemCount')}>
                        Items
                        <Arrow order={order}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                        </Arrow>
                    </HSDataTableHeader>

                    <HSDataTableHeader width="160"
                        css={theme.dataTableHeader.defaultProps.css}>
                        Actions
                        <Arrow>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                        </Arrow>
                    </HSDataTableHeader>
                </DataTableHead>

                <DataTableBody>
                    {data.map((item, index) => (
                        <HSDataTableRow key={item.id}
                            css={theme.dataTableRow.defaultProps.css}>
                            <DataTableCell css={theme.dataTableCell.defaultProps.css} isDate>
                                {moment(item.updatedOn).format('MM/DD/YYYY')}
                            </DataTableCell>
                            
                            <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                <a href={`/MyAccount/MyLists?id=${item.id}`}>{item.name}</a>
                            </DataTableCell>

                            <DataTableCell css={theme.dataTableCell.defaultProps.css} alignX="center">
                                {item.pagination ? item.pagination.totalItemCount : 0}
                            </DataTableCell>

                            <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                <HSWishlistLink href='#'onClick={e => {
                                        e.preventDefault();
                                        onBuy(item.id);
                                    }}>
                                    Buy
                                </HSWishlistLink>
                                <HSWishlistLink href='/Rfq'>Quote
                                </HSWishlistLink>
                                <HSWishlistLink href='#' 
                                    onClick={e => {
                                        e.preventDefault();
                                        setDeleteList(item)
                                        //onDeleteList(item);
                                    }}>
                                        Delete
                                    </HSWishlistLink>
                            </DataTableCell>
                        </HSDataTableRow>
                    ))}
                </DataTableBody>
            </DataTable>
            
            {/* Delete Modal */}
            <Modal
                isOpen={(deleteList.id) ? true : false}
                handleClose={() =>  setDeleteList({})}
                size='small'
                headline='Delete Wishlist'
                contentLabel="Delete Wishlist modal">
                    <p>Are you sure you want to delete {deleteList.name}?</p>
                    <HSModalFooter>
                        <HSCta href="#" onClick={e => {e.preventDefault(); setDeleteList({})}}>Cancel</HSCta>
                        <Button onClick={() => onDeleteList(deleteList)} variant="primary" css={`margin-left: 10px`}>Delete</Button>
                    </HSModalFooter>

            </Modal>

            {/* Buy Confirm */}
            <Modal
                isOpen={itemsAddedCart}
                handleClose={() => setItemsAddedCart(false)}
                size='small'
                headline='List added'
                contentLabel="List added to cart succesfully">
                    <p>The requested products have been added to your cart</p>
    
            </Modal>
        </> 
        : <div><NoResults variant="h3">No results found</NoResults></div>}

            <HSCta href="/MyAccount/MyLists">View All Wishlists</HSCta>
        </HSWishlist>
    )
}

export default Wishlist;