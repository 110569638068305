import globalTheme from '@insitesoft/mobius/globals/baseTheme';
import DataTableHeader from '@insitesoft/mobius/DataTable/DataTableHeader';
import DataTableRow from '@insitesoft/mobius/DataTable/DataTableRow';
import styled from 'styled-components';
import breakpointMediaQueries from '@insitesoft/mobius/utilities/breakpointMediaQueries';

export const theme = {
    ...globalTheme,
    colors: {
        common: {
            black: '#000807',
            white: '#fff',
            lightgrey3: '#dfdedd',
            darkgrey1: '#323232',
            grey2: '#d8d8d8',
            red: '#7f2526',
            lightred: '#8a2a2b',
            darkred: '#5b1a1a',
        },
        primary: {
            main: '#7f2526', 
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#fff',
            contrastText: '#7f2526'
        },
        tertiary: {
            main: '#fff', 
            contrastText: '#eb7b1e'
        },
    },
    breakpoints: {
        keys: ['sm', 'md', 'lg', 'dt', 'xl'],
        values: ['0', '768', '1024', '1280', '1440'],
        maxWidths: [1440, 1440, 1440, 1440, 1440]
    },
    fontWeight: {
        fontLight: 300,
        fontRegular: 400,
        fontSemibold: 600,
        fontBold: 700,
    },
    button: {
        ...globalTheme.button,
        defaultProps: {
            ...globalTheme.button.defaultProps,
            hoverMode: 'darken',
            hoverAnimation: 'none',
            activeMode: 'darken',
            shape: 'roundedq',
            shadow: false,
            color: '#fff',
            typographyProps: {
                size: '14px',
                weight: 700,
                transform: 'uppercase'
            },
            css: props => (`
                margin-bottom: 0;
            `)
        },
        primary: {
            color: '#fff',
            buttonType: 'outline',
            css: props => (`
                border: 2px solid  ${props.theme.colors.common.red};
                margin-bottom: 0;
                background-color:#7f2526;
                color: #fff ,
            `),
            hoverStyle: {
                background: '#7f2526',
                color: '#ffffff',
                borderColor: '#5b1a1a',
            }
        },

        link: {
            css: props => (`
                border: 2px solid ${theme.colors.common.red};
                text-align: center;
                color: ${theme.colors.common.red};
                font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                padding: 10px 22px;
                background-color: #fff;
                transition: .3s ease;
                
                &:hover {
                    background: ${theme.colors.common.red};
                }
                `
            ),

            hoverStyle: {
                color: '#fff',
                textDecoration: 'none',
        
            }
        }
    },

    select: {
        ...globalTheme.select,
        defaultProps: {
            ...globalTheme.select.defaultProps,
            cssOverrides: {
                formInputWrapper: props => (`
                    font-size: 14px;
                    select {
                        font-size: 14px;
                    }
                `),

                formField: props => (`
                    font-size: 14px;
                    select {
                        font-size: 14px;
                    }
                `),
            }
        }
    },

    textField: {
        ...globalTheme.textField,
        defaultProps: {
            ...globalTheme.textField.defaultProps,
            cssOverrides: {
                formInputWrapper: props => (`
                    margin-top: 0px !important;
                    input {
                        font-size: 14px;
                    }
                `)
            }
        }
    },
    
    datePicker: {
        ...globalTheme.datePicker,
        defaultProps: {
            ...globalTheme.datePicker.defaultProps,
            cssOverrides: {
                formInputWrapper: props => (`
                    width: 100%;
                    margin-top: 0px !important;

                    input {
                        font-size: 14px;
                    }

                    .DayPickerInput {
                        width: 100%;
                    }
                `),

                datePicker: props => (`
                    width: 100%;

                    .DayPickerInput {
                        width: 100%;
                    }
                `),

                // formField: props => (`
                //     padding-right: 10px;
                // `),
            }
        }
    },

    formField: {
        ...globalTheme.formField,
        defaultProps: {
            ...globalTheme.formField.defaultProps,
            labelProps: {
                size: '14px',
                weight: 700,
                color: '#7f2526'
            },

            cssOverrides: {
                formInputWrapper: props => (`
                    margin-top: 0px !important;

                    select, input {
                        font-size: 14px;
                    }
                `)
            }
        }
    },

    dataTable: {
        defaultProps: { 
            css: (props) => (`
                border: 0;
                table-layout: fixed;
                background: transparent;

                thead {
                    background: transparent;
                }
            `),
        }
    },

    dataTableHead: {
        defaultProps: { 
            css: (props) => (`
                &:nth-child(odd) {
                    background: transparent;
                    border: 0;
                }
            `),
        }
    },

    dataTableHeader: {
        defaultProps: { 
            css: (props) => (`
                color: #323232
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                height: auto;
                padding: 14px 15px;
                background: transparent;
                border: 0;

                ${props.hideTablet &&
                    breakpointMediaQueries(theme, [
                        'display: none',
                        null,
                        'display: table-cell',
                        null,
                        null,], 'min')
                }
            `),
        }
    },

    dataTableRow: {
        defaultProps: { 
            css: (props) => (`
                background: transparent;
                cursor: pointer:

                &:hover {
                    background: #dae8f5;
                }

                &:nth-child(odd) {
                    background: #f3f5f7;
                }

                &:nth-child(even) {
                    background: #fff;
                }

                td {
                    border: 0;
                }
            `),
        }
    },

    dataTableCell: {
        defaultProps: { 
            css: (props) => (`
                color: black;
                font-size: 14px;
                line-height: 14px;
                height: auto;
                padding: 14px 15px;
                border: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 400;
                span {
                    font-size: 15px;
                    font-weight: 400;
                }
                a {
                    color: black;
                    font-weight:400;
                }
                
                ${props.hideTablet &&
                    breakpointMediaQueries(theme, [
                        'display: none;',
                        null,
                        'display: table-cell;',
                        null,
                        null,], 'min')
                }
            `),
        }
    },

    tab: {
        ...globalTheme.tab,
        defaultProps: {
            css: (props) => (`
                padding: 7px 12px 4px;
                background: transparent;
                border-bottom-color: transparent;
                ${props.selected && 
                    `background: #8a2a2b;
                    color: #fff; 
                    border-bottom: 0px;
                    span {
                        color: #fff;
                    }`
                }
               
                &:hover {
                    border-bottom-color: transparent;
                }
                &:focus {
                    border-bottom-color: transparent;
                }
            `),
            typographyProps: {
                color: '#7f2526',
                size: '14px',
                weight: 700,
                textTransform: 'uppercase'
            }
        },
        groupDefaultProps: {
            cssOverrides: {
                tabContent: (props) => (`border: 0; padding: 20px 0;`),
                wrapper: (props) => (`
                    ul {
                        margin: 0;
                        padding: 0;
                        border-bottom: 2px solid #dde2e7;
                        flex: auto;
                    }
                `),
            }
        },
    },

    modal: {
        ...globalTheme.modal,
        defaultProps: {
            ...globalTheme.modal.defaultProps,
            cssOverrides: {
                modalTitle: props => (`
                    h4 {
                        margin: 0;
                    }
                `),

                titleButton: props => (`
                    width: 36px;
                    padding: 0;

                    &:hover {
                        background: ${props.theme.colors.common.red};
                        span {
                            color: #fff;
                        }
                    }
                `)
            }
        }
    },
    typography: {
        ...globalTheme.typography,
        h1: {
            fontSize: 'inherit'
        },
        h2: {
            fontSize: 'inherit'
        },
        h3: {
            fontSize: 'inherit'
        },
        h4: {
            fontSize: 'inherit'
        },
        h5: {
            fontSize: 'inherit'
        },
        h6: {
            fontSize: 'inherit'
        }
    }
};

export const HSDataTableHeader = styled(DataTableHeader) `
    position: relative;
    cursor: pointer;
    
    & > span {
        display: ${props => props.orderBy ? "inline-block" : "none"}
    }
`;

export const HSDataTableHeaderSimple = styled(DataTableHeader) `
    position: relative;
    cursor: default;
    
    & > span {
        display: "inline-block"}
    }
`;

export const HSDataTableRow = styled(DataTableRow) `
    cursor: pointer;

    &:hover {
        background: ${theme.colors.common.red} !important;

        td {
            color: ${theme.colors.common.white} !important;
        }
        a, p {
            color: ${theme.colors.common.white} !important;
        }
    }
`

export const HSDataTableRowNoPointer = styled(DataTableRow) `
    cursor: default;

    &:hover {
        background: ${theme.colors.common.red} !important;

        td {
            color: ${theme.colors.common.white} !important;
        }
        a, p {
            color: ${theme.colors.common.white} !important;
        }
    }
`

export const Arrow = styled.span`
    svg {
        color: black !important;
        margin-left: 10px;
        transform: rotateX(${props => props.order==="desc" ? "0" : "180deg"});
    }
`;

export const NoResults = styled.h2 `
    color: ${theme.colors.common.black}
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: normal;
`