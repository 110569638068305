import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import axios from 'axios';

const HSMenu = styled.ul `
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
`

const HSMenuItem = styled.li `
    a {
        display: block;
        color: #2d2d2d;
        font-size: 14px;
        font-weight: ${theme.fontWeight.fontSemibold};
        padding: 15px;
        border-bottom: 2px solid #dde2e7;
        background: ${props => props.active ? theme.colors.common.blue4 : "transparent"}

        &:hover {
            color: ${theme.colors.common.white};
            text-decoration: none;
            background-color: #7f2526;
        }
    }
`;

const NavigationLinks = ({links}) => {
    return (
        <HSMenu>
            {links.map((item, index) => (
                <HSMenuItem key={item.PageTitle}><a href={item.Url}>{item.PageTitle}</a></HSMenuItem>
            ))}
        </HSMenu>
    )
}

export default NavigationLinks;