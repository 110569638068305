import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import axios from 'axios';

const HSSettingsLink = styled.div `
    padding: 0 15px;
`

const HSSettingsTitle = styled.h4 `
    color: #868686;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

const HSMenu = styled.ul `
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0;
    width: 100%;
`

const HSMenuItem = styled.li `
    display: block;
    margin-bottom: 10px;

    a {
        color: #2d2d2d;
        font-size: 14px;
        font-weight: ${theme.fontWeight.fontSemibold};
        padding: 5px 0;
        text-transform: uppercase;
    
        &:hover {
            text-decoration: none;
            border-bottom: 2px solid #7f2526;
        }
    }
`;

const SettingsLinks = ({links}) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const signout = () => {
        axios.delete('/api/v1/sessions/current', {
            cancelToken: source.token
        })
        .then(response =>  window.location.href = '/')
        .catch(error => console.log(error))
    }

    return (
        <HSSettingsLink>
            <HSSettingsTitle>SETTINGS</HSSettingsTitle>
            <HSMenu>
                {links.map((item, index) => (
                    <HSMenuItem key={item.PageTitle}><a href={item.Url}>{item.PageTitle}</a></HSMenuItem>
                ))}

                <HSMenuItem>
                    <a href="#0" onClick={() => signout()}>
                        <span>Log Out</span>
                    </a>
                </HSMenuItem>
            </HSMenu>
        </HSSettingsLink>
    )
}

export default SettingsLinks;