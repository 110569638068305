import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeProvider }  from 'styled-components';
import { theme } from '../theme';
import breakpointMediaQueries from '@insitesoft/mobius/utilities/breakpointMediaQueries';
import GridContainer from '@insitesoft/mobius/GridContainer';
import GridItem from '@insitesoft/mobius/GridItem';
import Typography from '@insitesoft/mobius/Typography';
import Breadcrumbs from '@insitesoft/mobius/Breadcrumbs';
import AccountManager from './components/AccountManager';
import OrdersListing from './components/OrdersListing';
import Quotes from './components/Quotes';
import Wishlist from './components/Wishlist';


import NavigationLinks from './components/NavigationLinks';
import SettingsLinks from './components/SettingsLinks';
import axios from 'axios';

axios.interceptors.request.use(function(config){
    const bearerToken = window.localStorage.getItem('accessToken');
    if(bearerToken){
        config.headers.Authorization = `Bearer ${bearerToken}`;
    }
    return config;
}, function(err){
    return Promise.reject(err);
});

const HSMainWrapper = styled.div `
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 15px;

    ${breakpointMediaQueries(theme, [
        null,
        null,
        'margin-top: 0;',
        null,
        null], 'min')}
`

const HSSectionTitle = styled(Typography) `
    border-bottom: 2px solid rgba(149, 144, 144, 0.2);
    margin-top: 0;
    text-transform: uppercase;
    padding-bottom: 10px;
    color: #959090;
    font-size: 20px;
    font-weight: 700;
`
const HSBreadcrumbs = styled(Breadcrumbs) `

    ${props => props.isMobile ?
        breakpointMediaQueries(theme, [
            'display: block;',
            null,
            'display: none;',
            null,
            null], 'min'):
        breakpointMediaQueries(theme, [
            null,
            null,
            'display: block;',
            null,
            null], 'min')
    }

    ${breakpointMediaQueries(theme, [
        null,
        null,
        'margin-bottom: 20px; margin-top: 20px; padding: 0 15px;',
        null,
        null], 'min')}
`

const HSLeftBar = styled.div `
    position: absolute;
    display: flex;
    width: 300px;
    top: 60px;
    bottom: 0;
    z-index: 11;
    right: -100%;
    transition: right 0.3s ease;
    align-items: flex-start;
    background: #f4f4f4;

    ${breakpointMediaQueries(theme, [
        null,
        null,
        'position: relative; right: 0; top: 0; flex-basis: 25%; max-width: 25%; transition: none; padding-top: 10px;',
        null,
        null], 'min')}

    &.showbar {
        right: 0;
 
        &:before {
            position: absolute;
            display: block;
            width: 100vw;
            height: 100%;
            content: "";
            left: -100vw;
            background-color: #fff;
            opacity: 0.6;

            ${breakpointMediaQueries(theme, [
                null,
                null,
                'content: none;',
                null,
                null], 'min')}
        }
    }
`

const HSRightBar = styled.div `
    display: none;

    background: #fafdff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);

    & > div {
        width: 100%;

        ${breakpointMediaQueries(theme, [
            null,
            null,
            'padding-left: 30px; padding-top: 20px',
            null,
            null], 'min')}
    }

    ${breakpointMediaQueries(theme, [
        null,
        'display: block; flex-basis: 100%; max-width: 100%;',
        'flex-basis: 75%; max-width: 75%;',
        null,
        null], 'min')}
`

const HSMobileBlocks = styled.div `
    width: 100%;
    margin-top:20px;

    ${breakpointMediaQueries(theme, [
        null,
        'display: none;',
        null,
        null,
        null], 'min')}
`

const CloseButton = styled.button `
    border: 0;
    padding: 0;
    background: transparent;

    &:hover {
        background: transparent;
    }
`

const CloseWrapper  = styled.div `
    display: block;
    width: 100%;
    text-align: right;
    padding: 24px 14px 0;

    ${breakpointMediaQueries(theme, [
        null,
        null,
        'display: none;',
        null,
        null], 'min')}
`

const HSHeaderMobile = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ${breakpointMediaQueries(theme, [
        null,
        null,
        'display: none;',
        null,
        null], 'min')}
`

const HSOpenMenu = styled.button `
    border: 0;
    padding: 0;
    background: transparent;
    margin: 0;

    &:hover {
        background: transparent;
    }

    ${breakpointMediaQueries(theme, [
        null,
        null,
        'display: none;',
        null,
        null], 'min')}
`

const Dashboard = props => {
    const [showLeftBar, setShowLeftBar] = useState(false);
    const leftBarRef = React.createRef();
    const rightBarRef = React.createRef();

    const toggleLeftBar = () => {
        const ref =  showLeftBar ? document.querySelector('div.dash__rightbar') : document.querySelector('div.top-nav-container');
        if(showLeftBar) {
            setShowLeftBar(!showLeftBar);
            setTimeout(() => ref.parentNode.insertBefore(document.querySelector('div.dash__leftbar'), ref), 600);
        } else {
            ref.parentNode.insertBefore(leftBarRef.current, ref);
            setTimeout(() => setShowLeftBar(!showLeftBar), 100);
        }
    }
         
    return (
        <ThemeProvider theme={theme}>
            <HSMainWrapper>
                <HSHeaderMobile>
                    <HSBreadcrumbs links={props.breadcrumbs} isMobile/>
                    <HSOpenMenu onClick={() => toggleLeftBar()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="35" viewBox="0 0 8 35"><g><g><path fill="#7f2526" d="M0 4.2C0 1.925 1.833 0 4 0s4 1.925 4 4.2c0 2.275-1.833 4.2-4 4.2S0 6.475 0 4.2m0 13.3c0-2.275 1.833-4.2 4-4.2s4 1.925 4 4.2c0 2.275-1.833 4.2-4 4.2s-4-1.925-4-4.2m0 13.3c0-2.275 1.833-4.2 4-4.2s4 1.925 4 4.2C8 33.075 6.167 35 4 35s-4-1.925-4-4.2"/></g></g></svg>
                    </HSOpenMenu>
                </HSHeaderMobile>

                <HSLeftBar className={`dash__leftbar ${showLeftBar ? 'showbar' : null}`} ref={leftBarRef}>
                    <div style={{ width: '100%', zIndex: 2 }}>
                        <CloseWrapper>
                            <CloseButton onClick={() => toggleLeftBar()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g><g><path fill="#7f2526" d="M15 3.061L11.875 0 7.5 4.592 3.125 0 0 3.061l4.688 4.592L0 11.94 3.125 15 7.5 10.408 11.875 15 15 11.939l-4.375-4.286z"/></g></g></svg>
                            </CloseButton>
                        </CloseWrapper>
                        <AccountManager session={props.session}/>
                        <NavigationLinks links={props.navigationLinks || []}/>
                        <SettingsLinks links={props.settingsLinks || []}/>
                    </div>
                </HSLeftBar>

                <HSRightBar className="dash__rightbar" ref={rightBarRef}>
                    <div>
                        <HSSectionTitle variant="h3">Quotes</HSSectionTitle>
                        <Quotes systemMessages = {props.systemMessages} checkoutAddress={props.checkoutAddress}/>
                        {
                            (!props.session.userRoles || props.session.userRoles.indexOf("Requisitioner") === -1) &&
                                <>
                                    <HSSectionTitle variant="h3">Orders</HSSectionTitle>
                                    <OrdersListing session={props.session} productType=''/>
                                    <HSSectionTitle variant="h3">Lists</HSSectionTitle>
                                    <Wishlist/>
                                </>
                        }
                    </div>
                </HSRightBar>

                <HSMobileBlocks>
                    <ul className="dash-mobile__menu">
                        <li>
                            <a className = "dash-mobile__icon-container" href="/MyAccount/MyEmails">
                                MESSAGES
                                <img className = "dash-mobile__icon" src="/UserFiles/images/dash_message.png"></img>
                            </a>
                        </li>
                        <li>
                            <a className = "dash-mobile__icon-container" href="/MyAccount/VendingImportDashboardList">
                                VMI PENDING ORDERS
                                <img className = "dash-mobile__order-icon" src="/UserFiles/images/dash_vending.png"></img>
                            </a>
                        </li>
                        <li>
                            <a className = "dash-mobile__icon-container" href="/MyAccount/orders">
                                ORDERS
                                <img className = "dash-mobile__order-icon" src="/UserFiles/images/dash_orders.png"></img>
                            </a>
                        </li>
                        <li>
                            <a className = "dash-mobile__icon-container" href="/MyAccount/Rfq/MyQuotes">
                                QUOTES
                                <img className = "dash-mobile__icon" src="/UserFiles/images/dash_quotes.png"></img>                                
                            </a>
                        </li>
                        <li>
                            <a className = "dash-mobile__icon-container" href="/MyAccount/MyLists">
                                LISTS
                                <img className = "dash-mobile__lists-icon" src="/UserFiles/images/dash_lists.png"></img>
                            </a>
                        </li>
                    </ul>
                </HSMobileBlocks>
            </HSMainWrapper>
        </ThemeProvider>
    )
}

export default Dashboard;