import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import breakpointMediaQueries from '@insitesoft/mobius/utilities/breakpointMediaQueries';
import { theme } from '../../theme';

const HSAccountManager = styled.section `
    margin-bottom: 35px;
    padding: 15px;
`;

const Title = styled.h3`
    color: #868686;
    font-size: 16px;
    font-weight: ${theme.fontWeight.fontBold};
    text-transform: uppercase;
    margin-top: 0;
`
const Info = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    span {
        color: ${theme.colors.common.blue1};
        font-size: 16px;
        font-weight: ${theme.fontWeight.fontBold};
    }
`

const Phone = styled.div`
    color: ${theme.colors.common.darkgrey1};
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 14px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    
    svg {
        margin-right: 8px;
    }
`

const Email = styled.a`
    color: ${theme.colors.common.darkgrey1};
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 17px;
    word-break: break-all;
    width: 100%;
    align-items: center;

    ${breakpointMediaQueries(theme, [
        'display: none;',
        null,
        'display: inline-flex',
        null,
        null], 'min')}

    svg {
        margin-right: 8px;
    }
`

const Image = styled.div`
    flex: 0 0 45px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.21);
    margin-right: 20px;
    overflow: hidden;

    img {
        width: 60px;
        height: auto;
        max-width: none;
    }
`

const AccountManager = ({session}) => {
  const [data, setData] = useState({ SalesPerson: {}, Count: 0 });
  const [imageStatus, setImageStatus] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
        const result = await axios(
            `/api/v1/salesrepresentative/customerid/${session.billTo.id}`,
        );
        setData(result.data);
    };

    fetchData();
  }, []);

  function onErrorLoadImage(ev){
    try{
        ev.target.parentNode.remove();        
    }catch(e){}
    setImageStatus(true);
  }
  function onLoadImage(){
      setImageStatus(true);
  }


  return (
    data.SalesPerson.Name ?
        <HSAccountManager>
            <Title>Account Manager</Title>

            {data.SalesPerson.Name &&
                  <Info>
                    {data.SalesPerson.ImagePath !== "" ? <Image className={imageStatus ? "" : "hidden-no-space"}>
                        <img onLoad={onLoadImage} onError={onErrorLoadImage} src={data.SalesPerson.ImagePath} />
                    </Image> : null}
                    <span>{data.SalesPerson.Name}</span>
                </Info>
            }

            {data.SalesPerson.Phone1 &&
                <Phone>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><g><path fill="#7f2526" d="M6.087 2.955C5.993 2.21 4.165.052 3.578.003a2.057 2.057 0 0 0-1.142.27 7.674 7.674 0 0 0-.446.255l3.357 3.969c.42-.568.786-1.18.74-1.542z"/></g><g><path fill="#7f2526" d="M.413 1.899c-1.165 1.972.131 5.996 3.91 9.775 3.78 3.779 7.803 5.075 9.775 3.91.349-.241.653-.541.899-.887l-4.159-3.517c-.113.095-.218.186-.309.266a.612.612 0 0 1-.785.02 32.134 32.134 0 0 1-2.742-2.47 32.135 32.135 0 0 1-2.47-2.743.612.612 0 0 1 .019-.785c.08-.091.17-.196.266-.31L1.299 1c-.345.246-.645.55-.886.899z"/></g><g><path fill="#7f2526" d="M15.469 14.011c.092-.15.178-.3.255-.446.199-.347.292-.744.27-1.142-.05-.587-2.207-2.415-2.953-2.51-.362-.045-.973.321-1.541.741z"/></g></g></g></g></svg>
                    {data.SalesPerson.Phone1}
                </Phone>
            }

            {data.SalesPerson.Email &&
                <Email href = {'mailto:' + data.SalesPerson.Email}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12"><g><g><path fill="#7f2526" d="M17.14 1.333v9.685l-5.05-4.983zM15.783 0l-7.18 7.018L1.209 0zm.367 12H.853l5.052-5.193 2.703 2.526 2.704-2.526zM0 1.333l5.05 4.702L0 11.018z"/></g></g></svg>
                    {data.SalesPerson.Email}
                </Email>
            }

            {data.SalesPerson.Email &&
                <a className = "account-manager__send-mail-label" href={`mailTo:${data.SalesPerson.Email}`}>SEND MESSAGE</a>
            }
        </HSAccountManager>
    : null
  );
}

export default AccountManager;
