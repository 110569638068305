import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import DataTable from '@insitesoft/mobius/DataTable/DataTable';
import DataTableHead from '@insitesoft/mobius/DataTable/DataTableHead';
import DataTableBody from '@insitesoft/mobius/DataTable/DataTableBody';
import DataTableCell from '@insitesoft/mobius/DataTable/DataTableCell';

import { theme, HSDataTableHeader, Arrow, HSDataTableRow, NoResults } from '../../theme';

const HSQuotes = styled.section `
    width: 100%;
    margin-bottom: 40px;
`

const HSCta = styled.a `
    text-transform: uppercase;
    color: #2d2d2d !important;
    font-size: 16px;
    font-weight: 700;
`

const HSQuoteLink = styled.a `
    display: block;
    line-height: 24px;
    margin-right: 5px;
    font-weight: 700 !important;
    text-transform: uppercase;    
`

const BIQuoteConvertLabel = styled.p `
    display: block;
    position:relative;
    right: 10px;
    top:5px;
    line-height: 24px;
    font-weight: 700 !important;
    text-transform: uppercase;  
    color: black;
    &:hover {
        text-decoration:underline;
    }
`

const BIQuoteModal = styled.div `
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    top:405px;
    min-width: 45vw;
    min-height: 100px; 
    background-color: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    font-family: inherit;
    padding: 20px 30px;
    p {
        margin-bottom: 5px;
        align-self: center;
    }
`

const BIQuoteModalButton = styled.button `
    height: 40px;
    line-height: 0.4;
    color: #ffffff;
    font-family: inherit;
    border: 2px solid #2c4355;
    background-color: #4c7190;
    
    &:hover {
        background-color: #4c7190;
        color: #ffffff;
        border-color: #2c4355;
    }
`;

const apiUrl = 'api/v1/customrfq/quotes';

const Quotes = ({checkoutAddress, systemMessages}) => {

    const [data, setData] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('Date');
    const [showConvertModal, setShowConvertModal] = useState(false)
    const [convertModalMessage, setConvertModalMessage] = useState('Holaaa');

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getQuoteModal = () => {
        if(!showConvertModal) {
            return null;
        }

        return (
            <BIQuoteModal>
                <p>{convertModalMessage} </p>
                <BIQuoteModalButton onClick = {setShowConvertModal.bind(this, false)}>
                    Close
                </BIQuoteModalButton>
            </BIQuoteModal>
        )
    }

    const getQuoteTypeRelatedActionButton = (item, checkoutAddress) => {
        const id = item.erpOrderNumber;
 
        if (item.status === "P21 Quote") {
            return (
                <BIQuoteConvertLabel>
                    <a href={`/MyAccount/Rfq/MyQuotes/Details/?orderNumber=${id}`}>Details</a>
                </BIQuoteConvertLabel>
            )
        }

        return (
            <HSQuoteLink href={`${checkoutAddress}?cartId=${item.id}`}>
                Buy
            </HSQuoteLink>
        )
    }

    useEffect(() => {
        const fetchData = async () => {
            const now = new Date();
            const result = await axios.get(
                apiUrl, {
                    params: {
                        pageSize: 5,
                        date: now.toISOString()
                    }
                }
            );

            const allData = await getAllData(result.data.quotes);
            setData(allData);
        };

        const getAllData = async allList => {
            const pArray = allList.map(async item => {
                return {
                    ...item, ...await getAllQuoteData(item.id)
                }
            })
            const pAll = await Promise.all(pArray);

            return pAll
        }

        const getAllQuoteData = id => {
            return axios.get(`${apiUrl}/${id}`, {
                cancelToken: source.token
            })
            .then(response => response.data)
            .catch(error => console.log(error))
        }

        fetchData();
    }, []);

    const orderQuotesBy = param => {
        let dir, sortQuotes;
        if(param === orderBy) {
            dir = order === 'desc' ? 'asc' : 'desc';
        } else {
            dir = 'desc';
        }

        if  (dir === 'desc') {
            sortQuotes = _orderBy(data, q => {
                if(param === 'orderDate') {
                    return new Date(q.orderDate)
                }
                return q[param]
            }, 'desc');
        } else {
            sortQuotes = _orderBy(data,  q => {
                if(param === 'orderDate') {
                    return new Date(q.orderDate)
                }
                return q[param]
            }, 'asc');
        }

        setOrderBy(param);
        setOrder(dir)
        setData(sortQuotes);

    };
    return (
        <HSQuotes>
            { data && data.length ?
            <>
                <DataTable css={theme.dataTable.defaultProps.css}>
                    <DataTableHead  css={theme.dataTableHead.defaultProps.css}>
                        <HSDataTableHeader width='105'
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'orderDate'}
                            onClick={() => orderQuotesBy('orderDate')}>
                            Date
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'quoteNumber'}
                            onClick={() => orderQuotesBy('quoteNumber')}>
                            Quote#
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader hideTablet
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'poNumber'}
                            onClick={() => orderQuotesBy('poNumber')}>
                            PO#
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader hideTablet
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'salespersonName'}
                            onClick={() => orderQuotesBy('salespersonName')}>
                            Source
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader hideTablet width="80"
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'totalQtyOrdered'}
                            onClick={() => orderQuotesBy('totalQtyOrdered')}>
                            Items
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'statusDisplay'}
                            onClick={() => orderQuotesBy('statusDisplay')}>
                            Status
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader
                            css={theme.dataTableHeader.defaultProps.css}
                            orderBy={orderBy === 'orderGrandTotal'}
                            onClick={() => orderQuotesBy('orderGrandTotal')}>
                            Price
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                        <HSDataTableHeader width="80"
                            css={theme.dataTableHeader.defaultProps.css}>
                            Actions
                            <Arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>

                    </DataTableHead>

                    <DataTableBody>
                        {data.map((item, index) => (
                            <HSDataTableRow key={item.id}
                                css={theme.dataTableRow.defaultProps.css}>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css} isDate>
                                    {moment(item.orderDate).format('MM/DD/YYYY')}
                                </DataTableCell>
                                
                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    <a href={`/MyAccount/Rfq/MyQuotes/Details/?orderNumber=${item.erpOrderNumber}`}>{item.erpOrderNumber}</a>
                                </DataTableCell>

                                <DataTableCell css={theme.dataTableCell.defaultProps.css} hideTablet>
                                    {item.poNumber}
                                </DataTableCell>

                                <DataTableCell css={theme.dataTableCell.defaultProps.css} hideTablet>
                                    {item.salespersonName}
                                </DataTableCell>

                                <DataTableCell alignX="center" css={theme.dataTableCell.defaultProps.css} hideTablet>
                                    {item.totalQtyOrdered}
                                </DataTableCell>

                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    {item.statusDisplay}
                                </DataTableCell>

                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    {item.orderGrandTotalDisplay}
                                </DataTableCell>

                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    {getQuoteTypeRelatedActionButton(item, checkoutAddress)}                                    
                                </DataTableCell>
                            </HSDataTableRow>
                        ))}
                    </DataTableBody>
                </DataTable>

            </> 
        : <div><NoResults variant="h3">No results found</NoResults></div>}

            <HSCta href="/MyAccount/Rfq/MyQuotes">View All Quotes</HSCta>
            
            {getQuoteModal()}
            
        </HSQuotes>
    )
}

export default Quotes;