import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import _filter  from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import DataTable from '@insitesoft/mobius/DataTable';
import DataTableHead from '@insitesoft/mobius/DataTable/DataTableHead';
import DataTableRow from '@insitesoft/mobius/DataTable/DataTableRow';
import DataTableBody from '@insitesoft/mobius/DataTable/DataTableBody';
import DataTableCell from '@insitesoft/mobius/DataTable/DataTableCell';
import TextField from '@insitesoft/mobius/TextField';
import Select from '@insitesoft/mobius/Select';
import DatePicker from '@insitesoft/mobius/DatePicker';
import Button from '@insitesoft/mobius/Button';
import breakpointMediaQueries from '@insitesoft/mobius/utilities/breakpointMediaQueries';
import { theme, HSDataTableHeader, Arrow, NoResults, HSDataTableRow } from '../../theme';

const HSOrdersListing = styled.section `
    width: 100%;
    margin-bottom: 40px;
`
const HSFilters = styled.div `
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 17px;

    fieldset>div{
        height: 40px;
    }

    input,
    select,
    button {
        margin-bottom: 0;
    }
`;

const HSFiltersCriteria = styled.div `
    margin-right: 10px;

    ${breakpointMediaQueries(theme, [
        'width: 100%; flex: 0 0 100%; margin-bottom: 10px',
        null,
        'width: 29.24%; flex: 0 0 29.24%; margin-bottom: 0',
        null,
        null], 'min')}
`

const HSFiltersFilter = styled.div `
    margin-right: 10px;

    ${breakpointMediaQueries(theme, [
        'width: calc(30% - 10px); flex: 0 0 calc(30% - 10px);', 
        null,
        'width: 20.47%; flex: 0 0 20.47%;',
        null,
        null], 'min')}

`

const HSFiltersType = styled.div `
    margin-right: 10px;

    ${breakpointMediaQueries(theme, [
        'width: calc(70% - 87px); flex: 0 0 calc(70% - 87px);',
        null,
        'width: 35.63%; flex: 0 0 35.63%;',
        null,
        null], 'min')}

`

const HSFiltersTypeTwoCols = styled.div `
    display: flex;
    flex-wrap: wrap;

    fieldset {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        padding-right: 10px;

        &:last-child {
            padding-right: 0;
        }
    }
`

const BIReorderLink = styled.a `
    display: block;
    line-height: 24px;
    margin-right: 5px;
    font-weight: 700 !important;
`

const BIReorderModal = styled.div `
    position: relative;
    top: -435px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 500;
    min-width: 500px;
    min-height: 125px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.21);
`

const HSCta = styled.a `
    text-transform: uppercase;
    color: #2d2d2d !important;
    font-size: 16px;
    font-weight: 700;
`

const OrdersListing = ({session, productType}) => {
    const [orders, setOrders] = useState([]);
    const [searchFilter, setSearchFilter] = useState({});
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('orderDate DESC');
    const [criteria, setCriteria] = useState('');
    const [filter, setFilter] = useState('Date Range');
    const [timeframe, setTimeframe] = useState('30');
    const [dateRange, setDateRange] = useState({from: moment().subtract(1, 'months').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')});
    const [amountRange, setAmountRange] = useState({min: '', max: ''});
    const [initialLoad, setInitialLoad] = useState(false);
    const [reorderModalStatus, setReorderModalStatus] = useState("hidden");
    const [canReorderItems, setCanReorderItems] = useState(null);

    const tableHeadings = [
        {label: 'Date', id: 'orderDate'},
        {label: 'ID', id: 'productErpNumber'},
        {label: 'PO#', id: 'customerPO'},
        {label: 'Source', id: 'salesperson'},
        {label: 'Status', id: 'status'},
        {label: 'Price', id: 'orderTotal'}
    ];

    useEffect(() => {
        const fetchData = async () => {
            const url = productType ?  `/api/v1/customorder/orderhistorylines/type/${productType}` : '/api/v1/orders';
            const result = await axios.get(
                url, {
                    params: {
                        ...getSort(),
                        ...getFilters(),
                        expand: 'orderlines',
                        pageSize: 5,
                        customerSequence: -1,
                    }
                }
            );

            let ordersData = result.data.orders;
            if(productType) {
                ordersData = getOrdersByType(result.data.orders);
            }

            if(filter === 'Amount Range') {
                ordersData = removeMinAmountOrders(ordersData);
            }

            if (orderBy === "productErpNumber"
            || orderBy === "productErpNumber DESC"
            || orderBy === "description"
            || orderBy === "description DESC"
            || orderBy === "customerPO"
            || orderBy === "customerPO DESC") {
                ordersData = doSpecialSort(ordersData);
            }

            setOrders(ordersData);
            setInitialLoad(true);
        };

        const getOrderSettings = async () => {
            const orderSettingsURL = '/api/v1/settings';
            try {
                const result = await axios.get(orderSettingsURL, {});
                if (result && result.data && result.data.settingsCollection) {
                    const canReorderItemsResult = result.data.settingsCollection.orderSettings.canReorderItems;
                    setCanReorderItems(canReorderItemsResult);
                    if(canReorderItemsResult) {
                        tableHeadings.push({label: 'Actions', id: 'actions'});
                    }
                }
            } catch (error) {}
        }

        if(canReorderItems == null) {
            getOrderSettings();
        }

        fetchData();

    }, [orderBy, searchFilter]);

    const reorderAction = async (event, order) => {
        event.preventDefault();
        const reorderURL = '/api/v1/carts/current/cartlines/batch'
        const orderLines = getFormattedOrderLines(order.orderLines);

        try {
            const result = await axios.post(
                reorderURL, {
                    cartLines: orderLines
                }
            );
            if (result.status == 200 || result.status == 201) {
                setReorderModalStatus("success");
            }
        } catch (error) {
            setReorderModalStatus("error");
        }
        
    }

    const getReorderCell = (order) => {
        
        const hasOrderLines = order && order.orderLines && order.orderLines.length > 0;
        const canOrderBeAddedToCart = order && order.canAddToCart;
        let orderHasValidOrderLines = false;

        if (!(canReorderItems && hasOrderLines && canOrderBeAddedToCart)) {
            return null;
        }
        
        order.orderLines.forEach((orderLine) => {
            if(orderLine.canAddToCart) {
                orderHasValidOrderLines = true;
            }
        });
        
        if(!orderHasValidOrderLines) {
            return null;
        }

        return (
            <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                <BIReorderLink href = "#" onClick={(event) => reorderAction(event, order)}>
                    RE-ORDER
                </BIReorderLink> 
            </DataTableCell>    
        )
    }

    const convertToCartLine = (orderLine) => {
        const cartLine = {};
        cartLine.productId = orderLine.productId;
        cartLine.qtyOrdered = orderLine.qtyOrdered;
        cartLine.unitOfMeasure = orderLine.unitOfMeasure;
        return cartLine;
    }

    const getFormattedOrderLines = (orderLines) => {
        const cartLines = [];
        for (let i = 0; i < orderLines.length; i++) {
            if (orderLines[i].canAddToCart) {
                cartLines.push(convertToCartLine(orderLines[i]));
            }
        }

        return cartLines;
    }

    const getFilters = () => {
        if(!initialLoad) return;
        const filtersToApply = {};
        if(filter === 'Date Range') {
            filtersToApply.fromDate = dateRange.from;
            filtersToApply.toDate = dateRange.to;
        } else if(filter === 'Timeframe') {
            filtersToApply.toDate = moment().format('YYYY-MM-DD');
            filtersToApply.fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
        } else if(filter === 'Amount Range') {
            filtersToApply.ordertotal = amountRange.max;
            filtersToApply.ordertotaloperator = 'Less Than';
        }

        filtersToApply.productErpNumber = criteria;
        return filtersToApply;
    }

    const getReorderModal = () => {
        if (reorderModalStatus === "hidden") {
            return null;
        }

        let modalMessage = "Order items have been placed again on the cart.";
        if (reorderModalStatus === "error") {
            modalMessage = "An error occurred while trying to re-order.";
        }

        return (
            <BIReorderModal>
                {modalMessage}
                <Button variant="primary" onClick={() => {setReorderModalStatus("hidden")}}>
                    Close
                </Button>
            </BIReorderModal>
        )
    }

    const getSort = () => {
        const orderToApply = {};
        if (orderBy != "productErpNumber"
            && orderBy != "productErpNumber DESC"
            && orderBy != "description"
            && orderBy != "description DESC"
            && orderBy != "customerPO"
            && orderBy != "customerPO DESC") {
                orderToApply.sort = orderBy;
        }
        else {
            orderToApply.sort = "OrderDate DESC";
        }

        return orderToApply
    }

    const getOrdersByType = ordersData => {
       return _filter(ordersData, o => o.orderLines[0].properties.productType && o.orderLines[0].properties.productType === productType)
    }

    const changeSort = sort => {
        if (orderBy === sort && orderBy.indexOf(" DESC") < 0) {
            setOrderBy(sort.split(",").map(o => `${o} DESC`).join(","));
            setOrder('desc');
        } else {
            setOrderBy(sort);
            setOrder('asc');
        }
    }
    
    const doSpecialSort = ordersData => _orderBy(ordersData, o => o.orderLines[0][orderBy], order);

    const removeMinAmountOrders = ordersData => _filter(ordersData, o => o.orderTotal >= amountRange.min);

    return (
        <HSOrdersListing>
            <HSFilters>
                <HSFiltersCriteria>
                    <TextField
                        border="rectangle"
                        label="Search Criteria"
                        placeholder="Enter keyword or item #"
                        value={criteria}
                        onChange={event => setCriteria(event.target.value)}
                    />
                </HSFiltersCriteria>
                
                <HSFiltersFilter>
                    <Select
                        label="Filter"
                        border="rectangle"
                        value={filter}
                        onChange={event => setFilter(event.target.value)}>
                        <option value="Date Range">Date Range</option>
                        <option value="Timeframe">Timeframe</option>
                        <option value="Amount Range">Amount Range</option>
                    </Select>
                </HSFiltersFilter>

                <HSFiltersType>
                    {
                        filter === 'Date Range' &&
                        <HSFiltersTypeTwoCols>
                            <DatePicker
                                border="rectangle"
                                label="From"
                                selectedDay={new Date(`${dateRange.from}`)}
                                format={'y/MM/dd'}
                                onDayChange={day => setDateRange({from: moment(day).format('YYYY-MM-DD'), to: dateRange.to})}
                            />
                            <DatePicker
                                border="rectangle"
                                label="To"
                                format={'y/MM/dd'}
                                selectedDay={new Date(`${dateRange.to}`)}
                                onDayChange={day => setDateRange({from: dateRange.from, to: moment(day).format('YYYY-MM-DD')})}
                            />
                        </HSFiltersTypeTwoCols>
                    }
                    {
                        filter === 'Timeframe' &&
                        <Select
                            label="Timeframe"
                            border="rectangle"
                            value={timeframe}
                            onChange={event => setTimeframe(event.target.value)}>
                            <option value="30">Last 30 days</option>
                        </Select>
                    }

                    {
                        filter === 'Amount Range' &&
                        <HSFiltersTypeTwoCols>
                            <TextField
                                border="rectangle"
                                label="Min Amount"
                                placeholder="$0.00"
                                value={amountRange.min}
                                onChange={event => setAmountRange({min: event.target.value, max: amountRange.max})}
                            />

                            <TextField
                                border="rectangle"
                                label="Max Amount"
                                placeholder="$0.00"
                                value={amountRange.max}
                                onChange={event => setAmountRange({min: amountRange.min, max:  event.target.value})}
                            />
                        </HSFiltersTypeTwoCols>
                    }
                </HSFiltersType>
                <Button variant="primary" onClick={() => setSearchFilter(getFilters())}>Go</Button>
            </HSFilters>
        { orders && orders.length ?
        <>
            <DataTable css={theme.dataTable.defaultProps.css}>
                <DataTableHead  css={theme.dataTableHead.defaultProps.css}>
                    {tableHeadings.map(({label, id}) => (
                        <HSDataTableHeader key={id}
                            orderBy={orderBy === id || orderBy === `${id} DESC`}
                            onClick={() => changeSort(id)}
                            css={theme.dataTableHeader.defaultProps.css}
                            {...(id === 'customerPO' || id === 'salesperson' ? {hideTablet: true} : {})}
                            {...(id === 'orderDate' ? {width: '105'} : {})}>
                            {label}
                            <Arrow order={order}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8"><g><g><path fill="#7f2526" d="M4.5 8L0 0h9z"/></g></g></svg>
                            </Arrow>
                        </HSDataTableHeader>
                    ))}                
                </DataTableHead>
                <DataTableBody>
                {orders.map((order, index) => {
                    if (!order) {
                        return null;
                    }
                    const tableRowKey = order.orderLines && order.orderLines[0] ? 
                        order.orderLines[0].productErpNumber : index;

                     return (
                            <HSDataTableRow key={tableRowKey}
                                css={theme.dataTableRow.defaultProps.css}>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css} isDate>
                                    {moment(order.orderDate).format('MM/DD/YYYY')}
                                </DataTableCell>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    <a href={`/MyAccount/Orders/Details?orderNumber=${order.webOrderNumber}`}>{order.webOrderNumber}</a>
                                </DataTableCell>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css} hideTablet>
                                    {order.customerPO}
                                </DataTableCell>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css} hideTablet>
                                    {order.salesperson}
                                </DataTableCell>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    {order.statusDisplay}
                                </DataTableCell>
                                <DataTableCell css={theme.dataTableCell.defaultProps.css}>
                                    {order.orderTotalDisplay}
                                </DataTableCell>
                                {getReorderCell(order)} 
                            </HSDataTableRow>
                        )
                    
                })}
                </DataTableBody>
            </DataTable>
        </>
        : <div><NoResults>No results found</NoResults></div>}
        {
            productType === 'Service' &&
            <HSCta href="/MyAccount/Services">View All My Services</HSCta>
        }

        {
            productType === 'Training' &&
            <HSCta href="/MyAccount/Trainings">View All My Trainings</HSCta>
        }


        {
            (productType !== 'Training' && productType !== 'Service') &&
            <HSCta href="/MyAccount/Orders">View All My Orders</HSCta>
        }

        {getReorderModal()}
        
        </HSOrdersListing>
    )
}

export default OrdersListing;